<template>
  <div id="mine">
    <div class="mine-header">
      <div class="mine-header-content">
        <div class="mine-header-layout">
          <div class="mine-header-layout-top">
            <!-- <img :src="informationData.imgUrl" /> -->
            <div class="mine-user" @click="updata">
              <div class="mine-name">{{ informationData.customerInfo && informationData.customerInfo.name }}</div>
              <div class="mine-phone">{{ informationData.phone }}</div>
            </div>
          </div>
          <div class="mine-header-layout-bottom">
            <!-- <div class="mine-cp">
              <h4>10</h4>
              <p>优惠券</p>
            </div> -->
            <!-- <div class="mine-money">
              <h4>1000.00</h4>
              <p>我的余额</p>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="mine-mainContent">
      <div class="order">
        <div class="order-title">我的订单</div>
        <a class="order-btn" @click="myorder"
          >查看更多 <img :src="forward_icon_Inno"
        /></a>
      </div>
      <div class="order-status">
        <van-grid :column-num="3" :border="false">
          <van-grid-item
            :icon="IconList.daizhifu"
            text="待支付"
            :to="{ path: '/mineorder', query: { status: '5' } }"
          />
          <van-grid-item
            :icon="IconList.daishenhe"
            text="待审核"
            :to="{ path: '/mineorder', query: { status: '25' } }"
          />
          <van-grid-item
            :icon="IconList.daifahuo"
            text="待发货"
            :to="{ path: '/mineorder', query: { status: '40' } }"
          />
          <van-grid-item
            :icon="IconList.daishouhuo"
            text="待收货"
            :to="{ path: '/mineorder', query: { status: '45' } }"
          />
          <van-grid-item
            :icon="IconList.jiaoyiwancheng"
            text="交易完成"
            :to="{ path: '/mineorder', query: { status: '0' } }"
          />
          <van-grid-item
            :icon="IconList.jiaoyiquxiao"
            text="交易取消"
            :to="{ path: '/mineorder', query: { status: '-1' } }"
          />
        </van-grid>
      </div>
    </div>

    <div class="mine-function">
      <!-- <van-cell :icon="afterSalesIcon" title="我的售后" is-link /> -->
      <van-cell
        :icon="me_wodeshouhou_icon"
        title="我的客服"
        @click="poPKefu"
        is-link
      />
      <van-cell
        :icon="serviceCenterIcon"
        title="送达方信息"
        @click="viewSongDaFang"
        is-link
      />
      <!-- <van-cell :icon="meSetIcon" title="我的设置" is-link /> -->
    </div>
  </div>
</template>

<script>
import daizhifu from "../../assets/icon/orderstatus/me_payment_icon@2x.png";
import daishenhe from "../../assets/icon/orderstatus/me_daishenhe_icon@2x.png";
import forward_icon_Inno from "../../assets/icon/orderstatus/forward_icon_Inno@2x.png";
import me_wodeshouhou_icon from "../../assets/icon/orderstatus/kefu_icon.svg";
import daifahuo from "../../assets/icon/orderstatus/me_daifahuo_icon@2x.png";
import daishouhuo from "../../assets/icon/orderstatus/me_daishouhuo_icon@2x.png";
import jiaoyiwancheng from "../../assets/icon/orderstatus/me_jiaoyiwancheng_icon@2x.png";
import jiaoyiquxiao from "../../assets/icon/orderstatus/me_jiaoyiquxiao_icon@2x.png";
import afterSalesIcon from "../../assets/icon/me_after_sales_icon备份 3.svg";
import meSetIcon from "../../assets/icon/me_set_icon.svg";
import serviceCenterIcon from "../../assets/icon/me_service_center_icon.svg";

import { trackEvent } from '../../utils/utils'

import { getAccount } from "./service";

export default {
  data() {
    const IconList = {
      daizhifu,
      daishenhe,
      daifahuo,
      daishouhuo,
      jiaoyiwancheng,
      jiaoyiquxiao,
      me_wodeshouhou_icon,
      forward_icon_Inno,
    };
    return {
      IconList,
      afterSalesIcon,
      meSetIcon,
      serviceCenterIcon,
      me_wodeshouhou_icon,
      forward_icon_Inno,
      informationData: {},
    };
  },
  mounted() {
    getAccount().then((res) => {
      this.informationData = res.data;
    }).catch((err)=>{
      return err
    });
  },
  methods: {
    clickTrackEvent(eventName){
      trackEvent(null, eventName)
    },
    updata() {
      this.clickTrackEvent('点击客户信息')
      this.$router.push("/minemessage");
    },
    myorder() {
      this.clickTrackEvent('点击查看更多')
      this.$router.push("/mineorder");
    },
    poPKefu() {
      this.clickTrackEvent('点击我的客服')
      window.kefuButton.click();
    },
    viewSongDaFang() {
      this.clickTrackEvent('点击送达方信息')
      this.$router.push('/address')
    }
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
</style>
<style lang="less">
#mine {
  width: 100%;
  background: #f5f6f7;
  height: 100%;
  .mine-header {
    position: relative;
    width: 100%;
    height: 75px;
    background-image: @GradientColor;
    .mine-header-content {
      position: absolute;
      top: 24px;
      left: 50%;
      transform: translateX(-50%);
      width: 94%;
      height: 96px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.07);
      .mine-header-layout {
        width: 100%;
        height: 100%;
        .mine-header-layout-top {
          width: 100%;
          padding: 24px 0 0 24px;
          display: flex;
          img {
            border-radius: 50%;
            width: 48px;
            height: 48px;
          }
          .mine-user {
            margin-left: 12px;
            .mine-name {
              margin-bottom: 4px;
              height: 24px;
              line-height: 24px;
              font-size: 16px;
              font-weight: 500;
              color: #1c2529;
            }
            .mine-phone {
              height: 17px;
              line-height: 17px;
              font-size: 14px;
              font-family: "Montserrat", sans-serif;
              color: #1c2529;
            }
          }
        }
        .mine-header-layout-bottom {
          display: flex;
          width: 100%;
          .mine-cp {
            flex: 1;
            padding-top: 32px;
            h4 {
              margin: 0;
              width: 100%;
              height: 24px;
              line-height: 24px;
              text-align: center;
              font-size: 19px;
              color: #0096e0;
            }
            p {
              margin: 0;
              width: 100%;
              height: 20px;
              line-height: 20px;
              font-size: 12px;
              text-align: center;
            }
          }
          .mine-money {
            flex: 1;
            padding-top: 32px;
            h4 {
              margin: 0;
              width: 100%;
              height: 24px;
              line-height: 24px;
              text-align: center;
              font-size: 19px;
              color: #0096e0;
            }
            p {
              margin: 0;
              width: 100%;
              height: 20px;
              line-height: 20px;
              font-size: 12px;
              text-align: center;
            }
          }
        }
      }
    }
  }

  .mine-mainContent {
    margin-top: 60px;
    width: 100%;
    .order {
      display: flex;
      padding: 0 14px 0 12px;
      width: 100%;
      height: 36px;
      background: #fff;
      justify-content: space-between;
      align-items: center;
      .order-title {
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        text-align: left;
        color: #161823;
      }
      .order-btn {
        height: 14px;
        line-height: 14px;
        font-size: 12px;
        text-align: right;
        display: flex;
        color: #96979b;

        img {
          width: 12px;
          height: 12px;
        }
      }
    }
    .order-status {
    }
  }
  .mine-function {
    width: 100%;
    margin-top: 8px;
  }

  .van-grid-item__icon {
    font-size: 32px;
  }
  .van-grid-item__text {
    margin-top: 4px;
  }

  .mine-function {
    .van-icon__image {
      width: 24px;
      height: 24px;
    }

    .van-cell__left-icon {
      margin-right: 8px;
    }
  }

  .van-cell {
    padding: 14px 16px;
  }
}
</style>
